import React from 'react';
export interface CtaProps {
  text: string;
  customClass?: string;
  link: string;
}

const Cta: React.FC<CtaProps> = ({ link, customClass, text, ...props }) => {
  return (
    <>
      {text && link ? (
        <a
          href={link}
          className={`w-175 min-h-44 py-8 px-12 text-center rounded-full text-16 leading-16 text-white bg-theme focus:outline-none font-medium focus:outline-none disabled:cursor-not-allowed disabled:opacity-50 flex justify-center items-center border border-transparent ${customClass}`}
          target="_blank"
          rel="noreferrer"
          font-customisation="para-text"
          button-customisation="page-button"
          {...props}
        >
          {text}
        </a>
      ) : null}
    </>
  );
};

export default Cta;
