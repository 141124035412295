export const PRODUCT_CARD_WIDTH = 300;
export const PRODUCT_CARD_HEIGHT = 300;

export const CATEGORY_IMAGE_WIDTH = 280;
export const CATEGORY_IMAGE_HEIGHT = 280;

export const BANNER_IMAGE_WIDTH = 1160;
export const BANNER_IMAGE_HEIGHT = 556;

export const MOBILE_CATEGORY_IMAGE_WIDTH = 160;
export const MOBILE_CATEGORY_IMAGE_HEIGHT = 160;

export const MOBILE_BANNER_IMAGE_WIDTH = 390;
export const MOBILE_BANNER_IMAGE_HEIGHT = 280;

export const TESTIMONIALS_IMAGE_WIDTH = 100;
export const TESTIMONIALS_IMAGE_HEIGHT = 100;

export const MOBILE_TESTIMONIALS_IMAGE_WIDTH = 100;
export const MOBILE_TESTIMONIALS_IMAGE_HEIGHT = 100;

export const DEFAULT_LAYOUT_SELECTION = 11;
export const DEFAULT_NAVBAR_LAYOUT_SELECTION = 'PH4';
export const DEFAULT_SLIDER_LAYOUT_SELECTION = 'HB2';
export const DEFAULT_PRODUCTLIST_LAYOUT_SELECTION = 'PS4';
export const DEFAULT_CATEGORYLIST_LAYOUT_SELECTION = 'CS15';
export const DEFAULT_TESTIMONIALS_LAYOUT_SELECTION = 'Testimonial10';
export const DEFAULT_TEXT_LAYOUT_SELECTION = 'Text1';

export const SHOW_ALL_PRODUCTS_IN_HOME = false;

export const SECTION_HEADING_CLASSNAME = 'font-bold';
export const SLIDER_TOP_PADDING = 'pt-0';
export const LAYOUT_CONFIG = {};
export const COMMON_TOAST_MESSAGE = {};
