import StoreInfo from '@common/models/StoreInfo';
import { CustomPagesSliceType } from '@common/store/customPages/slice';
import { FeaturesSliceType } from '@common/store/features/slice';
import { FilterBarSliceType } from '@common/store/filterBar/slice';
import { PageHeaderSliceType } from '@common/store/pageHeader/slice';
import { PoliciesSliceTosType } from '@common/store/policiesTos/slice';
import { getActiveNavbar } from '@common/utils';
import { isMobile } from '@common/utils';
import Email from '@images/Email.svg';
import Facebook from '@images/Facebook.svg';
import Instagram from '@images/Instagram.svg';
import Linkedin from '@images/Linkedin.svg';
import Phone from '@images/Phone-call.svg';
import Pin from '@images/Pin.svg';
import Pinterest from '@images/Pinterest.svg';
import Twitter from '@images/Twitter.svg';
import Youtube from '@images/Youtube.svg';
import Image from '@lib/Image';
import Link from '@lib/Link';
import React from 'react';
import { connect } from 'react-redux';

import { RootState } from '../../../../common/store';

interface FooterProps {
  storeInfo: StoreInfo;
  customPages: CustomPagesSliceType;
  features: FeaturesSliceType;
  filterBar: FilterBarSliceType;
  policies: PoliciesSliceTosType;
  pageHeader: PageHeaderSliceType;
  pageHeaderID: number;
}

const Footer: React.FunctionComponent<FooterProps> = ({
  storeInfo,
  features,
  filterBar,
  policies: { policyTos },
  pageHeader,
  pageHeaderID,
}) => {
  const quickLinks =
    'text-center md:text-left md:w-full w-fit mx-auto md:mx-0 mb-14 md:mb-20 flex text-navbar text-14 leading-22 font-normal';
  const header = 'text-center md:text-left text-white leading-25 text-21 mb-24 font-bold';
  const arePoliciesPresent = Object.keys(policyTos).length > 0;
  const pageHeaderLayout = pageHeader[pageHeaderID]?.pageHeader;
  return (
    <div className="w-full bg-theme" color-customisation="footer-bg" id="page-footer">
      <div className="w-full container mx-auto pt-52 pb-0 md:pb-42" font-customisation="para-text">
        <div className="">
          <div className="block md:hidden py-12 ">
            <Link
              to="/"
              className="flex rounded justify-center md:justify-start w-111 mx-auto mb-32 overflow-hidden"
              id="mobile-footer-logo"
            >
              {pageHeaderLayout?.logo ? (
                <Image
                  noDefaultDimensions
                  src={pageHeaderLayout?.logo}
                  alt={storeInfo?.storename}
                  className="overflow-hidden object-contain h-44"
                />
              ) : (
                <div className="md:font-medium text-20 text-white ">{storeInfo?.storename}</div>
              )}
            </Link>
          </div>
          <div className=" md:justify-between justify-center">
            <div className="grid grid-cols-1 sm:grid-cols-4 gap-1 md:px-4">
              <div className="md:block hidden">
                <Link to="/" id="footer-logo">
                  {pageHeaderLayout?.logo ? (
                    <Image
                      noDefaultDimensions
                      src={pageHeaderLayout?.logo}
                      alt={storeInfo?.storename}
                      className=" h-44 rounded"
                    />
                  ) : (
                    <div className="font-medium text-30 text-white">{storeInfo?.storename}</div>
                  )}
                </Link>
              </div>
              <div className="mb-44 md:mb-0">
                <div className={header} font-customisation="section-heading" color-customisation="footer-heading">
                  Menu
                </div>
                {arePoliciesPresent && !policyTos?.privacy && (
                  <Link
                    to="/policy/privacy"
                    className={quickLinks}
                    color-customisation="footer-links"
                    id="footer-privacy"
                  >
                    Privacy
                  </Link>
                )}
                {arePoliciesPresent && !policyTos?.termsAndConditions && (
                  <Link
                    to="/policy/tnc"
                    className={quickLinks}
                    color-customisation="footer-links"
                    id="footer-terms-conditions"
                  >
                    Terms & Conditions
                  </Link>
                )}
                {features.features?.contactUsPage && getActiveNavbar('Contact Us', filterBar) ? (
                  <Link
                    to="/contactus"
                    className={quickLinks}
                    color-customisation="footer-links"
                    id="footer-contact-us"
                  >
                    Contact Us
                  </Link>
                ) : null}
                {getActiveNavbar('About Us', filterBar) && (
                  <Link to="/aboutus" className={quickLinks} id="footer-about-us" color-customisation="footer-links">
                    About Us
                  </Link>
                )}
                {getActiveNavbar('FAQs', filterBar) && (
                  <Link to="/faqs" className={quickLinks} id="footer-faqs" color-customisation="footer-links">
                    FAQs
                  </Link>
                )}
              </div>
              <div className="mb-44 md:mb-0">
                <div className={header} font-customisation="section-heading" color-customisation="footer-heading">
                  Account
                </div>
                <Link
                  to="/track-order"
                  className={quickLinks}
                  color-customisation="footer-links"
                  id="footer-track-order"
                >
                  Track Order
                </Link>
                {arePoliciesPresent && !policyTos?.cancelAndRefund && (
                  <Link
                    to="/policy/cancellation"
                    className={quickLinks}
                    color-customisation="footer-links"
                    id="footer-cancellation-refund"
                  >
                    Cancellation &amp; Refund
                  </Link>
                )}
                {arePoliciesPresent && !policyTos?.shippingAndDelivery && (
                  <Link
                    to="/policy/shipping"
                    className={quickLinks}
                    color-customisation="footer-links"
                    id="footer-shipping-delivery"
                  >
                    Shipping &amp; Delivery
                  </Link>
                )}
                {/* {customPages?.pages?.results?.map((page) => {
                  if ((getBuyerJWTToken() && page.protected) || !page.protected) {
                    return (
                      <Link key={page.id} to={page.url} className={quickLinks}>
                        {page.name}
                      </Link>
                    );
                  } else {
                    return null;
                  }
                })} */}
              </div>
              <div className="md:mb-0 border-b pb-30 md:border-none md:pb-0 border-white border-opacity-50">
                <div className={header} font-customisation="section-heading" color-customisation="footer-heading">
                  Company
                </div>

                <div className=" text-priceText ">
                  <p className={quickLinks} color-customisation="footer-links">
                    <Image
                      noDefaultDimensions={true}
                      src={Pin}
                      alt="Email"
                      className="mr-14 hidden"
                      id="footer-contact-address"
                    />
                    {storeInfo?.contactInfo?.address}
                  </p>
                  <p className={quickLinks} color-customisation="footer-links">
                    <Image
                      noDefaultDimensions={true}
                      src={Phone}
                      alt="Phone"
                      className="mr-14 hidden"
                      style={{ filter: 'brightness(0.3)' }}
                    />
                    <a href={`tel:${storeInfo?.contactInfo?.number}`} id="footer-contact-number">
                      {storeInfo?.contactInfo?.number}
                    </a>
                  </p>
                  <p className={quickLinks} color-customisation="footer-links">
                    <Image
                      noDefaultDimensions={true}
                      src={Email}
                      alt="Email"
                      className="mr-14 hidden"
                      style={{ filter: 'brightness(0.3)' }}
                    />
                    <a
                      href={`${isMobile() ? 'mailto:' : 'https://mail.google.com/mail/?view=cm&fs=1&tf=1&to='}${
                        storeInfo?.contactInfo?.email
                      }`}
                      className="underline"
                    >
                      <p className="" id="footer-contact-mail">
                        {storeInfo?.contactInfo?.email}
                      </p>
                    </a>
                  </p>
                </div>
              </div>
              <div>
                <span className="hidden md:flex mt-0 mb-20 md:mt-60 md:mb-0">
                  {storeInfo?.hasSocialLinks ? (
                    <div className="flex items-center justify-start md:justify-center flex-no-wrap">
                      <div className="mb-12 md:mb-0 mr-0 md:mr-40">
                        <p
                          className="text-14 md:text-16 leading-24 text-navbar font-normal w-64"
                          color-customisation="footer-heading"
                        >
                          Follow us
                        </p>
                      </div>
                      <div className="flex items-center opacity-50">
                        {storeInfo?.social?.facebook ? (
                          <a
                            href={storeInfo?.social?.facebook}
                            title="Facebook"
                            target="_black()"
                            className="bg-iconbg rounded w-30 h-30 mr-10 md:mr-34 flex justify-center items-center"
                          >
                            {/* <FacebookFooter className="md:w-24 md:h-24 md:mr-24 w-18 mr-24  h-18" /> */}
                            <Image
                              noDefaultDimensions={true}
                              className="md:w-20 md:h-20 w-17 h-17"
                              src={Facebook}
                              id="footer-facebook-link"
                            />
                          </a>
                        ) : null}
                        {storeInfo?.social?.twitter ? (
                          <a
                            href={storeInfo?.social?.twitter}
                            title="Twitter"
                            target="_black()"
                            className="bg-iconbg rounded w-30 h-30 mr-10 md:mr-34 flex justify-center items-center"
                          >
                            {/* <TwitterFooter className="md:w-24 md:h-24 md:mr-24 w-18 mr-24  h-18" /> */}
                            <Image
                              noDefaultDimensions={true}
                              className="md:w-20 md:h-20 w-17 h-17"
                              src={Twitter}
                              id="footer-twitter-link"
                            />
                          </a>
                        ) : null}
                        {storeInfo?.social?.instagram ? (
                          <a
                            href={storeInfo?.social?.instagram}
                            title="Instagram"
                            target="_black()"
                            className="bg-iconbg rounded w-30 h-30 mr-10 md:mr-34 flex justify-center items-center"
                          >
                            {/* <InstagramFooter className="md:w-24 md:h-24 md:mr-24 w-18 mr-24  h-18" /> */}
                            <Image
                              noDefaultDimensions={true}
                              className="md:w-20 md:h-20 w-17 h-17"
                              id="footer-instagram-link"
                              src={Instagram}
                            />
                          </a>
                        ) : null}
                        {storeInfo?.social?.pinterest ? (
                          <a
                            href={storeInfo?.social?.pinterest}
                            title="Pinterest"
                            target="_black()"
                            className="bg-iconbg rounded w-30 h-30 mr-10 md:mr-34 flex justify-center items-center"
                          >
                            {/* <PinterestFooter className="md:w-24 md:h-24 md:mr-24 w-18 mr-10  h-18" /> */}
                            <Image
                              noDefaultDimensions={true}
                              className="md:w-20 md:h-20 w-17 h-17"
                              src={Pinterest}
                              id="footer-pinterest-link"
                            />
                          </a>
                        ) : null}
                        {storeInfo?.social?.youtube ? (
                          <a
                            href={storeInfo?.social?.youtube}
                            title="YouTube"
                            target="_black()"
                            className="bg-iconbg rounded w-30 h-30 mr-10 md:mr-34 flex justify-center items-center"
                          >
                            {/* <YoutubeFooter className="md:w-24 md:h-24 md:mr-24 w-18 mr-24  h-18" /> */}
                            <Image
                              noDefaultDimensions={true}
                              className="md:w-20 md:h-20 w-17 h-17"
                              src={Youtube}
                              id="footer-youtube-link"
                            />
                          </a>
                        ) : null}
                        {storeInfo?.social?.linkedin ? (
                          <a
                            href={storeInfo?.social?.linkedin}
                            title="LinkedIn"
                            target="_black()"
                            className="bg-iconbg rounded w-30 h-30 mr-10 md:mr-34 flex justify-center items-center"
                          >
                            {/* <LinkedinFooter className="md:w-24 md:h-24 md:mr-24 w-18 mr-24 h-18" /> */}
                            <Image
                              noDefaultDimensions={true}
                              className="md:w-24 md:h-16 w-16 h-15"
                              src={Linkedin}
                              id="footer-linkedin-link"
                            />
                          </a>
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                  {/* <InstamojoLogo /> */}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <span className="flex md:hidden mt-40 mb-0 md:mt-60 md:mb-0 pb-40">
          {storeInfo?.hasSocialLinks ? (
            <div className="flex items-center w-full justify-center flex-no-wrap">
              <div className="md:mb-0 mr-20 md:mr-40">
                <p
                  className="text-16 leading-24 text-navbar font-normal w-64"
                  font-customisation="para-text"
                  color-customisation="footer-heading"
                >
                  Follow us
                </p>
              </div>
              <div className="flex items-center opacity-50">
                {storeInfo?.social?.facebook ? (
                  <a
                    href={storeInfo?.social?.facebook}
                    title="Facebook"
                    target="_black()"
                    className="bg-iconbg rounded w-30 h-30 mr-10 md:mr-34 flex justify-center items-center"
                  >
                    {/* <FacebookFooter className="md:w-24 md:h-24 md:mr-24 w-18 mr-24  h-18" /> */}
                    <Image
                      noDefaultDimensions={true}
                      className="md:w-20 md:h-20 w-17 h-17"
                      src={Facebook}
                      id="mobile-footer-facebook-link"
                    />
                  </a>
                ) : null}
                {storeInfo?.social?.twitter ? (
                  <a
                    href={storeInfo?.social?.twitter}
                    title="Twitter"
                    target="_black()"
                    className="bg-iconbg rounded w-30 h-30 mr-10 md:mr-34 flex justify-center items-center"
                  >
                    {/* <TwitterFooter className="md:w-24 md:h-24 md:mr-24 w-18 mr-24  h-18" /> */}
                    <Image
                      noDefaultDimensions={true}
                      className="md:w-20 md:h-20 w-17 h-17"
                      src={Twitter}
                      id="mobile-footer-twitter-link"
                    />
                  </a>
                ) : null}
                {storeInfo?.social?.instagram ? (
                  <a
                    href={storeInfo?.social?.instagram}
                    title="Instagram"
                    target="_black()"
                    className="bg-iconbg rounded w-30 h-30 mr-10 md:mr-34 flex justify-center items-center"
                  >
                    {/* <InstagramFooter className="md:w-24 md:h-24 md:mr-24 w-18 mr-24  h-18" /> */}
                    <Image
                      noDefaultDimensions={true}
                      className="md:w-20 md:h-20 w-17 h-17"
                      src={Instagram}
                      id="mobile-footer-instagram-link"
                    />
                  </a>
                ) : null}
                {storeInfo?.social?.pinterest ? (
                  <a
                    href={storeInfo?.social?.pinterest}
                    title="Pinterest"
                    target="_black()"
                    className="bg-iconbg rounded w-30 h-30 mr-10 md:mr-34 flex justify-center items-center"
                  >
                    {/* <PinterestFooter className="md:w-24 md:h-24 md:mr-24 w-18 mr-10  h-18" /> */}
                    <Image
                      noDefaultDimensions={true}
                      className="md:w-20 md:h-20 w-17 h-17"
                      src={Pinterest}
                      id="mobile-footer-pinterest-link"
                    />
                  </a>
                ) : null}
                {storeInfo?.social?.youtube ? (
                  <a
                    href={storeInfo?.social?.youtube}
                    title="YouTube"
                    target="_black()"
                    className="bg-iconbg rounded w-30 h-30 mr-10 md:mr-34 flex justify-center items-center"
                  >
                    {/* <YoutubeFooter className="md:w-24 md:h-24 md:mr-24 w-18 mr-24  h-18" /> */}
                    <Image
                      noDefaultDimensions={true}
                      className="md:w-20 md:h-20 w-17 h-17"
                      src={Youtube}
                      id="mobile-footer-linkedin-link"
                    />
                  </a>
                ) : null}
                {storeInfo?.social?.linkedin ? (
                  <a
                    href={storeInfo?.social?.linkedin}
                    title="LinkedIn"
                    target="_black()"
                    className="bg-iconbg rounded w-30 h-30 mr-10 md:mr-34 flex justify-center items-center"
                  >
                    {/* <LinkedinFooter className="md:w-24 md:h-24 md:mr-24 w-18 mr-24 h-18" /> */}
                    <Image
                      noDefaultDimensions={true}
                      className="md:w-24 md:h-16 w-16 h-15"
                      src={Linkedin}
                      id="mobile-footer-linkedin-link"
                    />
                  </a>
                ) : null}
              </div>
            </div>
          ) : null}
          {/* <InstamojoLogo /> */}
        </span>
      </div>
    </div>
  );
};

export default connect(({ pageHeader }: RootState) => ({ pageHeader }))(Footer);
